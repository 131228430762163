import { useMutation } from '@apollo/client/index.js';
import { useAuth } from '@asaprint/asap/contexts/AuthContext.js';
import { UseUserSettings_Save } from '@asaprint/asap/hooks/useUserSettings.graphql';
import {
  UseUserSettings_SaveMutation,
  UseUserSettings_SaveMutationVariables,
} from '@asaprint/asap/schema.client.types.js';
import { getLogger } from '@engined/core/services/logger.js';
import { Dispatch, SetStateAction, useCallback, useRef } from 'react';

const logger = getLogger('@intranet/hooks/useUserSettings');

export default function useUserSettings<T>(
  key: string,
  initialValue: T,
): [T | undefined, Dispatch<SetStateAction<T | undefined>>] {
  const { requestUser, setSetting } = useAuth();
  const storeValueRef = useRef<T>(requestUser.settings?.[key] ?? initialValue);

  if (requestUser.settings?.[key] && requestUser.settings[key] !== storeValueRef.current) {
    // Update local ref if value changed by different useUserSettings hook
    storeValueRef.current = requestUser.settings[key];
  }

  const [execute] = useMutation<UseUserSettings_SaveMutation, UseUserSettings_SaveMutationVariables>(
    UseUserSettings_Save,
  );

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to database.
  const setValue = useCallback(
    async (value) => {
      try {
        // Allow value to be a function so we have same API as useState
        const valueToStore = value instanceof Function ? await value(storeValueRef.current) : value;
        if (valueToStore !== storeValueRef.current) {
          storeValueRef.current = valueToStore;
          // Save to auth context
          setSetting(key, valueToStore);
          // Save state to DB
          await execute({
            variables: {
              key,
              value: valueToStore,
            },
          });
        }
      } catch (error) {
        // A more advanced implementation would handle the error case
        logger.error(error);
      }
    },
    [storeValueRef, execute, key, setSetting],
  );

  return [storeValueRef.current, setValue];
}
