
            import createOneQuery from '@engined/core/build/graphql-plugin-lib';
            
            const doc = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UseUserSettings_Save"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"key"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}},"directives":[]},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"value"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"JSON"}},"directives":[]}],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"mySettingsSave"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"key"},"value":{"kind":"Variable","name":{"kind":"Name","value":"key"}}},{"kind":"Argument","name":{"kind":"Name","value":"value"},"value":{"kind":"Variable","name":{"kind":"Name","value":"value"}}}],"directives":[]}]}}],"loc":{"start":0,"end":126}};
            doc.loc.source = {"body":"\n          mutation UseUserSettings_Save($key: String!, $value: JSON) {\n  mySettingsSave(key: $key, value: $value)\n}\n\n        ","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
          

    const names = new Set<string>();
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          const name = def.name.value
          if (names.has(name)) {
            return false;
          } else {
            names.add(name);
            return true;
          }
        }
      )
    }
  

              const oneQuery = createOneQuery(doc);
              export default doc;
            
                  export const UseUserSettings_Save = oneQuery(doc, "UseUserSettings_Save");
                
